const fr = {
  accessoryUnavailable: "Accessoire(s) indisponible(s)",
  activity: "Activité",
  add: "Ajouter",
  addDocuments: "Ajouter des documents",
  addEquipmentInformation: "Ajouter des informations sur l'équipement",
  addEvent: "Ajouter un événement",
  addFile: "Ajouter un fichier",
  addInformation: "Ajouter les informations",
  additionalInformation: "Informations complémentaires",
  addMessageToCustomer: "Message pour le client",
  addNewDocument: "Ajouter un nouveau document",
  addResource: "Ajouter une ressource",
  address: "Adresse",
  agencyIsRequired: "L'agence est requise",
  allFilesUpToDate: "Tous les fichiers sont à jour",
  allMyOrders: "Toutes mes commandes",
  allResources: "Toutes les ressources",
  alreadyCustomer: "Déjà client ?",
  analytics: "Analytiques",
  anErrorOccurred: "Une erreur est survenue",
  apiStatus: "État de l'API",
  applicationVersion: "Version de l'application",
  areYouSureYouWantToDelete: "Êtes-vous sûr de vouloir supprimer ?",
  areYouSureYouWantToDeleteWithItem: "Êtes-vous sûr de vouloir supprimer {{item}} ?",
  articleAccess: "Accès à l'article",
  articleEquipmentUpdated: "Informations de l'équipement mises à jour",
  articleInformation: {
    message: "Pour une bonne utilisation client, ajoutez ces informations avant le début de la prestation.",
    warningDescription: "Fournissez les documents et informations nécessaires avant la location.",
    warningTitle: "Informations de l'équipement manquantes",
  },
  articleNameIsRequired: "Le nom de l'article est requis",
  articles: "Articles",
  assignment: "Affectation",
  associatedMachine: "Machine associée",
  availability: "Disponibilité",
  available: "Disponible",
  backToLogin: "Retour à page de connexion",
  beginning: "Début",
  between: "entre",
  billing: "Facturation",
  billings: "Facturations",
  bookingDate: "{{fromDate}} au {{toDate}}",
  bookingEnd: "Fin de la location",
  bookings: "Locations",
  bookingStart: "Début de la location",
  bookingStartTomorrow: "La location commence demain",
  bookingStartTomorrowMessage:
    "Veillez à ce que tout soit prêt pour la livraison en respectant les contraintes du client et en incluant les accessoires demandés le cas échéant.",
  brand: "Marque",
  brandIsRequired: "La marque est requise",
  breadcrumbs: {
    booking: {
      requests: "n°{{id}}",
    },
  },
  by: "Par",
  byFile: "par fichier",
  cancel: "Annuler",
  canceled: "Annulée",
  category: "Catégorie",
  categoryName: "Nom de la catégorie",
  ceCertificate: "Certificat CE",
  changePasswordAccount: "Changer le mot de passe de votre compte Tracktor",
  clickToUpload: "Cliquez pour télécharger",
  client: "Client",
  close: "Fermer",
  closed: "Clôturé",
  closedAtIncidentAlert: "Clôturé le {{date}} par {{by}}",
  closeTheIncident: "Clôturer l'incident",
  code: "Code",
  codeOrKeysLocation: "Code ou l'emplacement des clés",
  company: "Entreprise",
  completed: "Terminée",
  confirm: "Confirmer",
  confirmed: "Confirmé",
  contacts: "Contacts",
  continue: "Continuer",
  countryCode: "Code pays",
  createAPasswordForYourAccount: "Créez un mot de passe pour votre compte",
  currentApiVersion: "Version de l'API",
  currentApiVersionUsed: "Version de l'API actuellement utilisée",
  darkMode: "Mode sombre",
  darkTheme: "Thème sombre",
  dashboard: "Tableau de bord",
  dataSheet: "Fiche technique",
  dayCount_one: "{{count}} jour",
  dayCount_other: "{{count}} jours",
  deals: "Commandes",
  declaredByUserAt: "Déclaré par {{user}} le {{date}}",
  delete: "Supprimer",
  deleteBookingFileMessage: "Voulez-vous supprimer ce document de la commande ?",
  deleteFile: "Supprimer le fichier",
  deleteFileConfirmation: "La suppression du fichier est définitive. Êtes-vous sûr de vouloir continuer ?",
  deleteResource: "Supprimer la ressource",
  deleteResourceConfirmation:
    "La suppression d'une ressource est irréversible et supprimera tous les fichiers qui y sont liés. Êtes-vous sûr de vouloir continuer ?",
  delivery: "Livraison",
  deliveryAndReturn: "Livraison et Reprise",
  deliveryTimeConstraint: "Contrainte horaire de livraison",
  document: "Document",
  documentAdded: "Document ajouté",
  documentDeleted: "Document supprimé",
  documentName: "Nom du document",
  documents: "Documents",
  documentType: "Type de document",
  documentUpdated: "Document mis à jour",
  easilyManageYourRequests: "Gérez facilement vos demandes, suivez les commandes et optimisez vos activités en toute simplicité.",
  edit: "Modifier",
  editInfoDocument: "Modifier les informations du document",
  editMyPassword: "Modifier mon mot de passe",
  editResource: "Modifier la ressource",
  emailSent: "Email envoyé",
  emailSentForgotPassword: "Un email vous a été envoyé pour réinitialiser votre mot de passe.",
  emptyBooking: "Vous n’avez aucune location à venir",
  emptyBookingMissing: "Vous n'avez aucune demandes manquées",
  end: "Fin",
  enterTheCode: "Entrez le code",
  enterYourCode: "Entrez votre code",
  enterYourMessage: "Entrez votre message",
  equipment: "Matériel",
  equipmentInformation: {
    maximumPlates: "Pression maximale sous la plaque de répartition (T / m2)",
    sizePlates: "Dimension des plaques de répartition (m2)",
    skid: "Descente de charge maximale au patin (T)",
  },
  equipmentNotOffered: "Matériel non proposé",
  equipments: "Matériels",
  error: {
    unknownError: "Erreur inconnue, veuillez réessayer",
  },
  error404: "Erreur 404",
  errorLoadingRequests: "Une erreur est survenue lors du chargement des demandes",
  eventStatus: {
    accepted: "Demande de location acceptée",
    canceled: "Demande de location annulée",
    refused: "Demande de location refusée",
    sent: "Demande de location reçue",
  },
  expirationDate: "Date d'expiration",
  expired: "Expiré",
  file: "le document",
  fileAddedSuccessfully: "Fichier ajouté avec succès",
  fileDeletedSuccessfully: "Fichier supprimé avec succès",
  fileHasBeenUploaded: "Le fichier a bien été téléchargé",
  fileName: "Nom du fichier",
  files: "Fichiers",
  filesNotUpToDate: "Certains fichiers ne sont pas à jour",
  fileStatus: {
    expired: "Expiré",
    expires_soon: "Expire bientôt",
    up_to_date: "À jour",
  },
  fileType: "Type de fichier",
  finishDayCount: "Se termine dans {{count}} jours",
  finishToday: "Termine aujourd'hui",
  finishTomorrow: "Termine demain",
  firstName: "Prénom",
  flatRate: "Forfait",
  forgottenPassword: "Mot de passe oublié",
  forgottenPasswordSubtitle: "Entrez votre adresse e-mail. Nous vous enverrons un lien pour réinitialiser votre mot de passe.",
  form: {
    constraint: {
      password: "Le mot de passe doit contenir au moins 8 caractères et une majuscule",
    },
  },
  generalTerms: "Conditions générales",
  globalSearchPlaceholder: "Saisissez un n° de location ou un nom d'article",
  happyToSeeYouAgain: "Heureux de vous revoir !",
  help: "Aide",
  helperText: {
    onlyImageFilesAreAllowed: "Seuls les fichiers .png, .jpeg, .pdf sont autorisés",
  },
  home: "Accueil",
  icon: "Icône",
  identity: "Identité",
  identityCard: "Carte d'identité",
  import: "Importer",
  importFile: "Importer un fichier",
  incidentAddedSuccessfully: "Incident ajouté avec succès",
  incidentClosed: "Incident cloturé",
  incidentOpened: "Incident ouvert",
  indicateCodeOrKeysLocation: "Indiquez le code ou l'emplacement des clés",
  information: "Information",
  informations: "Informations",
  inProgress: "En cours",
  instructionManual: "Manuel d'instruction",
  insurance: "Assurance",
  internalCodeIsRequired: "Le code interne est requis",
  internalReference: "Référence interne",
  invoice: "Facture",
  irreversibleAction: "Cette action est irreversible",
  key: "Clé",
  keyLocation: "Emplacement des clés",
  keys: "Clés",
  language: "Langage",
  lastName: "Nom",
  linkDocuments: "Lier les documents",
  linkDocumentsToBooking: "Documents visibles par le client",
  list: "Liste",
  login: "Connexion",
  logout: "Déconnexion",
  machineRegistration: "Immatriculation de la machine",
  mail: "Email",
  maintenanceLog: "Carnet de maintenance",
  makeAnOffer: "Faire une offre",
  material: "Matériel",
  materialDropOffPoint: "Point de dépôt du matériel",
  messageToCustomer: "Message à destination du client",
  missed: "Manqués",
  newDate: "Nouvelle date",
  newDateProposal: "Disponible à partir d’une autre date proche",
  newDocument: "Nouveau document",
  newPassword: "Nouveau mot de passe",
  newPasswordConfirm: "Confirmer le nouveau mot de passe",
  newResourceCreated: "Votre nouvelle ressource a été créée ! ",
  newResourceCreatedDetails: " Enregistrer maintenant les documents et leur date de validité.",
  nextAssignment: "Prochaine affectation",
  no: "Non",
  noAvailability: "Aucune disponibilité et déclassement impossible",
  noDocumentsFound: "Aucun document trouvé",
  noEvents: "Aucun événement",
  noNameFound: "Aucun nom trouvé",
  noOrderFound: "Aucune commande reliée à cette article n'a été trouvée",
  notAvailable: "Indisponible",
  notes: "Notes",
  notFound: "La page que vous recherchez est introuvable.",
  notFoundRedirection: "Veuillez vérifier l'URL ou revenir à la page précédente.",
  num: "N°",
  offerSendTo: "Votre offre a été envoyée à {{name}}",
  offerSendToDetails: "Vous serez averti dès que votre client approuvera votre offre.",
  open: "Ouvert",
  operator: "Opérateur",
  operatorDocument: "Document de l'opérateur",
  order: "Commande",
  orderHistory: "Historique des commandes",
  orders: "Commandes",
  orderSummary: "Récapitulatif de la commande",
  other: "Autre",
  oups: "Oups !",
  overview: "Vue d'ensemble",
  pageNotFound: "Page non trouvée",
  password: "Mot de passe",
  passwordSetSuccessfully: "Votre mot de passe a bien été mis à jour",
  passwordsNotMatching: "Les mots de passe ne correspondent pas",
  passwordUpdated: "Votre mot de passe a bien été mis à jour",
  payments: "Paiements",
  periodicGeneralInspection: "Inspection générale périodique (VGP)",
  phone: " Téléphone",
  planning: "Planning",
  pleaseSetAPassword: "Veuillez définir un mot de passe",
  priceExVAT: "Prix HT",
  profile: "Mon profil",
  profileUpdated: "Votre profil a bien été mis à jour",
  purchaseOrder: "Bon de commande",
  purchaseOrderNumber: "Numéro de bon de commande",
  quantity: "Quantité",
  reason: "Motif",
  refused: "Refuser",
  refusedBookingRequestMessage: "En confirmant le refus de cette demande de location, vous ne pourrez plus l’accepter par la suite.",
  refusedTheBooking: "Refuser la location",
  reload: "Recharger",
  rentalRequests: "Demandes",
  reopenTheIncident: "Rouvrir l'incident",
  requests: "Demandes",
  requestStatus: {
    expired: "Manquées",
    proposed: "Offres",
    refused: "Refusées",
    toTreat: "À traiter",
  },
  requestWithID: "Demande de location n°{{id}}",
  resetInitialRequest: "Rétablir la demande initiale",
  resetPassword: "Réinitialiser le mot de passe",
  resetPrice: "Réinitialiser le prix",
  resource: "Ressource",
  resourceAddedSuccessfully: "Ressource ajoutée avec succès",
  resourceCreated: "Ressource créée",
  resourceEmptyState: {
    button: "Ajouter votre première ressource",
    dialogContentText:
      "Ajouter plusieurs ressources en une seule fois en important un fichier (CSV, XLS, XLSX, PDF...) ou ajoutez manuellement vos ressources.",
    dialogPrimaryButton: "Ajouter manuellement",
    dialogSecondaryButton: "Importer un fichier",
    dialogSuccessContentText:
      "Ressources transférées, Le traitement de vos ressources est en cours. Elles seront bientôt disponibles dans votre espace et une confirmation vous sera envoyée par mail.",
    dialogSuccessTitle: "Ressources transférées",
    dialogTitle: "Choisissez votre méthode",
    listSubtitle1: "Ajoutez une nouvelle ressource dans votre espace",
    listSubtitle2: "Et précisez les dates de validité (VPG, CACES...)",
    listSubtitle3: "L'ensemble des documents seront transmis au client",
    listTitle1: "Enregistrez une ressource",
    listTitle2: "Ajoutez des documents",
    listTitle3: "Affectez la à une commande",
    title: "Ajoutez votre première ressource (machine, opérateur...) et simplifiez la gestion de vos commandes",
  },
  resourceName: "Nom de la ressource",
  resourcePhoto: "Photo de la ressource",
  resources: "Ressources",
  resourceUpdated: "Ressource mise à jour",
  resourceUpdatedSuccessfully: "Ressource mise à jour avec succès",
  respondToDemand: "Répondre à la demande",
  return: "Retour",
  returnTo: "Retourner aux",
  save: "Enregistrer",
  saveChanges: "Enregistrer les modifications",
  saveTimeManagingYourOrders: "Gagnez du temps dans la gestion de vos commandes !",
  search: "Recherche",
  see: "Voir",
  seeDetail: "Voir le détail",
  seeOffer: "Voir l'offre",
  seeTheResource: "Voir la ressource",
  selectAnAgency: "Sélectionnez une agence",
  selectDocumentType: "Sélectionner le type de document",
  selectExpirationDate: "Sélectionner la date d'expiration",
  selectResource: "Sélectionnez une ressource",
  selectResourceWarning: "En sélectionnant cette ressource, vous confirmez qu'elle répond aux besoins du client.",
  sendOffer: "Envoyer l'offre",
  sent: "Envoyé",
  serialNumber: "N° de série",
  settings: "Paramètres",
  startDayCount: "Commence dans {{count}} jours",
  startToday: "Commence aujourd'hui",
  startTomorrow: "Commence demain",
  status: "Statut",
  stayConnected: "Rester connecté(e)",
  supplierComment: "Commentaire du fournisseur",
  theBookingRequestHasBeenAccepted: "Votre offre a bien été envoyée",
  theBookingRequestHasBeenRefused: "La demande de location a été refusée",
  to_add: "À ajouter",
  toAdd: "À ajouter",
  toCome: "À venir",
  today: "Aujourd'hui",
  toDo: "À faire",
  tokenVerification: "Vérification du token",
  total: "Total",
  totalHT: "Total HT",
  toUpdate: "Mettre à jour",
  typeToSearch: "Tapez pour rechercher",
  units: {
    DAYS_one: "{{count}} Jour",
    DAYS_other: "{{count}} Jours",
    HOURS_one: "{{count}} Heure",
    HOURS_other: "{{count}} Heures",
    KM: "{{count}} Km",
    null: "{{count}} ",
  },
  up_to_date: "À jour",
  uploadFile: "Télécharger un fichier",
  upToDate: "À jour",
  usedBy: "Utilisé par",
  validate: "Valider",
  worksiteAccessConstraints: "Contraintes d'accès au chantier",
  yes: "Oui",
  yourOffer: "Votre offre",
  yourOrdersAreWaitingForYouLogin: "Vos commandes vous attendent, connectez-vous !",
  yourResourcesWillBeAddedToYourSpaceAfterProcessing: "Vos ressources seront ajoutées à votre espace après traitement",
} as const;

export default fr;
